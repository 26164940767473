<template>
  <!--创建服务商-->
  <div>
    <div class="title">
      <div class="title_t">
        <span>服务商认证审核</span>
      </div>
      <div class="title_r" @click="returnPage">
        <span>返回</span>
      </div>
    </div>
    <el-form ref="form" label-position="left" :model="form" label-width="160px">
      <el-card shadow="never">
        <el-row :gutter="15" style="margin-top: 20px">
          <el-col :span="10">
            <el-row :gutter="5" style="margin-top: 20px">
              <el-col :span="12">
                <img
                  v-viewer
                  :src="form.business_license"
                  alt=""
                  style="width: 230px; height: 246px"
                />
              </el-col>
              <el-col :span="12">
                <img
                  v-viewer
                  :src="form.logo"
                  alt=""
                  style="width: 230px; height: 246px"
                />
              </el-col>
            </el-row>
            <el-row :gutter="5" style="margin-top: 20px">
              <el-col :span="12">
                <img
                  v-viewer
                  :src="form.legal_person_identify_front"
                  alt=""
                  style="width: 230px; height: 246px"
                />
              </el-col>
              <el-col :span="12">
                <img
                  v-viewer
                  :src="form.legal_person_identify_reverse"
                  alt=""
                  style="width: 230px; height: 246px"
                />
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="14">
            <div
              style="display: flex; align-items: center; margin-bottom: 20px"
            >
              <span
                style="
                  width: 5px;
                  height: 19px;
                  background: #ff9b05;
                  display: inline-block;
                  margin-right: 10px;
                "
              ></span>
              <span
                style="
                  font-size: 20px;
                  font-weight: 700;
                  color: #202033;
                  margin-right: 20px;
                "
                >认证信息</span
              >
            </div>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="企业名称">
                  <el-input
                    :disabled="true"
                    clearable
                    v-model="form.company_name"
                    placeholder="请输入企业名称"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="信用代码">
                  <el-input
                    :disabled="true"
                    clearable
                    v-model="form.credit_code"
                    placeholder="请输入信用代码"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="企业注册日期">
                  <el-input
                    :disabled="true"
                    clearable
                    v-model="form.registration_date"
                    placeholder="请输入企业注册日期"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="注册地址">
                  <el-input
                    :disabled="true"
                    clearable
                    v-model="form.detailed_address"
                    placeholder="请输入注册地址"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="法定代表人">
                  <el-input
                    :disabled="true"
                    clearable
                    v-model="form.legal_person_name"
                    placeholder="请输入法定代表人"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="注册资本">
                  <el-input
                    :disabled="true"
                    clearable
                    v-model="form.registered_capital"
                    placeholder="请输入注册资本"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="法定代表人身份证">
                  <el-input
                    :disabled="true"
                    clearable
                    v-model="form.legal_person_identity_number"
                    placeholder="请输入企业注册日期"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <div
                style="display: flex; align-items: center; margin-bottom: 20px"
              >
                <span
                  style="
                    width: 5px;
                    height: 19px;
                    background: #ff9b05;
                    display: inline-block;
                    margin-right: 10px;
                  "
                ></span>
                <span
                  style="
                    font-size: 20px;
                    font-weight: 700;
                    color: #202033;
                    margin-right: 20px;
                  "
                  >企业基本信息</span
                >
              </div>
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="企业简称">
                    <el-input
                      :disabled="true"
                      clearable
                      v-model="form.facilitator_abbreviation"
                      placeholder="请输入企业注册日期"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="企业客服电话">
                    <el-input
                      :disabled="true"
                      clearable
                      v-model="form.service_phone"
                      placeholder="请输入客服电话"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="调度负责人">
                    <el-input
                      :disabled="true"
                      clearable
                      v-model="form.dispatch_name"
                      placeholder=""
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="调度负责人电话">
                    <el-input
                      :disabled="true"
                      clearable
                      v-model="form.dispatch_phone"
                      placeholder=""
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-row>
            <el-row :gutter="15">
              <div
                style="display: flex; align-items: center; margin-bottom: 20px"
              >
                <span
                  style="
                    width: 5px;
                    height: 19px;
                    background: #ff9b05;
                    display: inline-block;
                    margin-right: 10px;
                  "
                ></span>
                <span
                  style="
                    font-size: 20px;
                    font-weight: 700;
                    color: #202033;
                    margin-right: 20px;
                  "
                  >企业收款信息</span
                >
              </div>
              <el-row :gutter="15" class="styletop">
                <el-col :span="12">
                  <el-form-item
                    label="账户名称"
                    prop="collection_account_name"
                  >
                    <el-input
                      clearable
                      :disabled="true"
                      v-model="form.collection_account_name"
                      placeholder="请输入账户名称"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="收款账号"
                    label-width=""
                    prop="collection_account_number"
                  >
                    <el-input
                      clearable
                      :disabled="true"
                      v-model="form.collection_account_number"
                      placeholder="请输入收款账号"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15" class="styletop">
                <el-col :span="12">
                  <el-form-item label="开户行" prop="collection_opening_bank">
                    <el-input
                      clearable
                      :disabled="true"
                      v-model="form.collection_opening_bank"
                      placeholder="请输入开户行"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="收款账号户银联号"
                    label-width="140px"
                    prop="collection_bank_code"
                  >
                    <el-input
                      clearable
                      :disabled="true"
                      v-model="form.collection_bank_code"
                      placeholder="请输入收款账号户银联号"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-row>
            <el-row :gutter="15">
              <div
                style="display: flex; align-items: center; margin-bottom: 20px"
              >
                <span
                  style="
                    width: 5px;
                    height: 19px;
                    background: #ff9b05;
                    display: inline-block;
                    margin-right: 10px;
                  "
                ></span>
                <span
                  style="
                    font-size: 20px;
                    font-weight: 700;
                    color: #202033;
                    margin-right: 20px;
                  "
                  >账号信息</span
                >
              </div>
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="系统管理员">
                    <el-input
                      :disabled="true"
                      clearable
                      v-model="form.account_name"
                      placeholder=""
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="管理员手机号">
                    <el-input
                      :disabled="true"
                      clearable
                      v-model="form.account_phone"
                      placeholder=""
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="认证备注">
                    <el-input
                      :disabled="true"
                      clearable
                      v-model="form.remark"
                      placeholder=""
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-row>
          </el-col>
        </el-row>
      </el-card>

      <!--基本信息-->

      <!--审核不通过理由-->
      <!-- <el-card shadow="never">
        <div style="display: flex; align-items: center">
          <span
            style="
              width: 5px;
              height: 19px;
              background: #ff9b05;
              display: inline-block;
              margin-right: 10px;
            "
          ></span>
          <span
            style="
              font-size: 20px;
              font-weight: 700;
              color: #202033;
              margin-right: 20px;
            "
            >审核不通过理由</span
          >
        </div>
        <div style="margin-top: 15px; margin-bottom: 15px">
          <el-input
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 8 }"
            placeholder="请输入审核不通过的理由"
            v-model="cause"
          >
          </el-input>
        </div>
      </el-card> -->
    </el-form>

    <!--按钮-->
    <!-- <div class="card_bottom">
      <el-button
        style="
          width: 240px !important;
          padding-top: 15px;
          padding-bottom: 15px;
          background: #f54e71 !important;
          border-color: #f54e71 !important;
          color: #ffffff !important;
        "
        @click="Failtheaudit"
        >审核不通过</el-button
      >
      <el-button
        style="
          width: 240px !important;
          padding-top: 15px;
          padding-bottom: 15px;
          background: #36dd9e !important;
          border-color: #36dd9e !important;
          color: #ffffff !important;
        "
        @click="passtheaudit"
        >审核通过</el-button
      >
    </div> -->
    <div class="card_bottom">
      <el-button
        style="
          width: 240px !important;
          padding-top: 15px;
          padding-bottom: 15px;
          background: #f54e71 !important;
          border-color: #f54e71 !important;
          color: #ffffff !important;
        "
        @click="fn"
        >审核不通过</el-button
      >
      <el-button
        style="
          width: 240px !important;
          padding-top: 15px;
          padding-bottom: 15px;
          background: #36dd9e !important;
          border-color: #36dd9e !important;
          color: #ffffff !important;
        "
        @click="passtheaudit"
        >审核通过</el-button
      >
    </div>

    <el-dialog title="提示" :visible.sync="dialogVisibles" width="30%">
      <div style="margin-bottom: 5px">
        <strong style="margin-bottom: 15px"
          >确认审核不通过,请输入不通过理由</strong
        >
        <br />
        <el-input
          type="textarea"
          :autosize="{ minRows: 5, maxRows: 8 }"
          placeholder="请输入审核不通过的理由"
          v-model="cause"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibles = false">取 消</el-button>
        <el-button type="primary" @click="Failtheaudit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { auditcheck, Detailsfacilitators, auditcheckv} from "@/api/yunli";
import axios from "axios";
import setting from "@/config/setting";
export default {
  name: "index",
  // 支柱道具属性
  props: {
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
    // id
    id: Number,
  },
  // 组件生效
  components: {},
  // Data数据
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      //城市列表
      city: [],
      //审核不通过理由
      dialogVisibles: false,
      cause: "",
      option: {
        // Viewer.js options
        inline: true,
        button: true,
        navbar: true,
        title: true,
        toolbar: true,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
        url: "data-source",
      },
      version: setting.version,
    };
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getDetails();
    this.getCity();
  },
  // 方法集合
  methods: {
    fn() {
      this.dialogVisibles = true;
      this.cause = "";
    },
    returnPage() {
      this.$emit("showtoexamine", false);
    },

    // 获取详情信息
    getDetails() {
      Detailsfacilitators(this.data.id).then((res) => {
        console.log(res);
        this.form = res.data;
      });
    },

    // 获取城市列表
    getCity() {
      axios({
        method: "GET",
        url: setting.commonurl + "/city_list",
      }).then((res) => {
        // console.log(res)
        this.city = res.data.data;
      });
    },

    //审核通过
    passtheaudit() {
      let where = {
          approve_status: 1,
        };
        auditcheckv(this.form.id, where)
          .then((res) => {
            console.log(res);
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.$emit("showtoexamine", false);
              this.$emit("dome");
            } else {
              this.$message.success(res.msg);
            }
          })
          .catch((e) => {
            this.$message.success(e.msg);
          });
    },

    //审核不通过
    Failtheaudit() {
      if (this.cause === "") {
        this.$message.error("请输入审核不通过理由");
      } else {
        let where = {
            approve_status: -1,
            reason: this.cause,
          };
          auditcheckv(this.form.id, where)
            .then((res) => {
              console.log(res);
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.$emit("showtoexamine", false);
                this.$emit("dome");
              } else {
                this.$message.success(res.msg);
              }
            })
            .catch((e) => {
              this.$message.error(e.msg);
            });
      }
    },
  },
  // 生命周期 - 更新之前
  beforeUpdate() {},
  // 生命周期 - 更新之后
  updated() {},
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {},
  // 生命周期 - 挂载之前
  beforeMount() {},
  // 生命周期 - 销毁之前
  beforeDestroy() {},
  // 生命周期 - 销毁完成
  destroyed() {},
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated() {},
  // 离开的时候触发
  deactivated() {},
};
</script>

<style scoped lang="scss">
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
